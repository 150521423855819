import * as React from "react";
import { HeadFC } from "gatsby";
import DefaultLayout from "../components/default-layout";

const NotFoundPage = () => {
  return (
    <DefaultLayout>
      <div className="text-whale flex flex-col items-center space-y-3">
        <div className="text-8xl">404</div>
        <div className="text-3xl">Well this is awkward!</div>
        <div>
          This page does not exist. Please click back or navigate to another
          page
        </div>
      </div>
    </DefaultLayout>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Notyphi - Not found</title>;
