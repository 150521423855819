import React from "react";
import logoImg from "../assets/logo-dark.svg";

const DefaultLayout: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <div>
      <div className="bg-squeeze min-h-screen">
        <div className="bg-whale flex flex-col items-center">
          <div className="container flex items-center justify-between py-4 text-xl text-white">
            <a href="/" className="text-white no-underline">
              <div className="flex items-center">
                <img alt="" className="mr-2 h-10" src={logoImg} />
              </div>
            </a>

            <div className=" top-2 right-0 flex flex-col gap-y-2  p-4">
              <a
                href="https://notyphi.com/login"
                className="text-white no-underline hover:underline"
              >
                login
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="container">{children}</div>
        </div>
      </div>
      <div className="bg-whale flex flex-col items-center">
        <div className="container py-3 text-right text-sm text-white">
          <div>Fetch.ai</div>
          <div className="text-xs text-gray-600">60</div>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
